import React from 'react';
import Seo from '../components/molecules/Seo';
import styled from 'styled-components';
import TwoColumn from '../components/organisms/TwoColumn';
import template from '../../static/images/member-template.jpg';
import Image from '../components/atoms/Image';
import MembershipCard from '../components/molecules/MembershipCard';
import { graphql } from 'gatsby';

const Holder = styled.div`
  p, li { ${props => props.theme.fluidType( 1 )}; }
  button, .button {
    margin-top: 2rem;
  }
`;

const MembersHolder = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 2rem;
  margin: 2rem 0;
  @media( ${props => props.theme.breakpoints.lg} ) { 
    grid-template-columns: repeat(3, 1fr);
  }
  .gatsby-image-wrapper {
    margin-bottom: 1rem;
  }
  p { 
    margin: 0; 
    ${props => props.theme.fluidType( 0 )};
  }
  .name { text-transform: capitalize; }
`;

function MembersPage( { data } ) {

  return (
    <>
      <Seo title="Members" description="Unicorns Forever members page. Become a member here."/>
      <Holder>
        <TwoColumn>
          <div>
            <MembershipCard/>
          </div>
          <div>
            <h1>Would you like to become a member?</h1>
            <p><b>Benefits:</b></p>
            <ul>
              <li>A laminated membership card.</li>
              <li>Show it at our market stalls and get a high five!</li>
              <li>Get special members only deals delivered to your email.</li>
            </ul>
            <p>To become a member download the template below and send it to us via email or post. We will sign you up to our email list and then make
              and mail out your membership card. Fun!</p>
            <p><a className="button" href={template} target="_blank" rel="noopener noreferrer">Download
              template</a></p>
          </div>
        </TwoColumn>
        <h2>Our members!</h2>
        <MembersHolder>
          {data.allFile.nodes.map( node =>
            <div key={node.id}>
              <Image imgName={node.childImageSharp.fluid.originalName}/>
              <p className="name">{node.name.replace( '-', ' - ' )}yrs old</p>
              <p>Member since: {node.birthTime}</p>
            </div>
          )}
        </MembersHolder>
      </Holder>
    </>
  )
}

export default MembersPage;

export const memberPageQuery = graphql`
    query MemebersQuery {
        allFile(
            filter: {absolutePath: {regex : "/images/members/"}}
            sort: {order: [ASC, ASC], fields: [name, birthTime]}
        ) {
            nodes {
                birthTime(formatString: "YYYY")
                name
                id
                childImageSharp {
                    fluid {
                        originalName
                    }
                }
            }
        }
    }
`;

